import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { PortableText } from "@portabletext/react";

import sanityConnectInfo from "../../sanityConnectInfo.json";
import formatDynamicAges from "../utils/formatDynamicAges";
import components from "../utils/componentsForPortableText";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import Articles from "../components/blog/ArticlesSection";
import AboutCard from "../components/cards/AboutCardPortrait";

import SupportBand from "../components/general/SupportBand";

const portableTextComponents = {
  ...components,

  block: {
    normal: ({ children }) => {
      let output = [...children];
      output.forEach((_, i) => {
        if (typeof output[i] === "string") {
          formatDynamicAges(output[i]);
        }
      });

      return <p>{output}</p>;
    },
  },
};

const AuthorStyles = styled.div`
  padding-left: 5%;
  padding-right: 5%;

  .author {
    display: grid;
    grid-template-columns: 500px 1fr;
    align-items: center;
    gap: 2rem;

    img {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: 1200px) {
      grid-template-columns: 300px 1fr;
    }

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 680px) {
      margin-top: 15%;
    }
  }

  .more-articles-button {
    margin-bottom: 2rem;

    a {
      padding: 1rem;
      background-color: var(--black);
      color: var(--white);
      margin-left: 5%;
    }
  }

  .bottom-newsletter {
    margin-bottom: 1rem;
  }
`;

export function Head({ data: { author }, location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{
        title: `Author: ${author.name.toUpperCase()}`,
        description: author.description,
        image: author.image.image.asset.gatsbyImageData,
        alt: author.image.alt,
        ogType: "article",
        createdAt: author._createdAt,
        updatedAt: author._updatedAt,
      }}
      location={location}
    />
  );
}

export default function Author({
  data: {
    author,
    blogs: { nodes: blogs },
    about,
    paymentGateways,
  },
  pageContext,
  location,
}) {
  return (
    <>
      <AuthorStyles>
        <div className="author">
          <div className="author-image">
            <GatsbyImage
              image={author.image.image.asset.gatsbyImageData}
              alt={author.image.alt}
            />
          </div>

          <div className="author-info">
            <h1>{author.name}</h1>

            <p>
              <strong>{author.caption}</strong>
            </p>

            {author.biography && author.biography._rawRichText ? (
              <PortableText
                value={author.biography._rawRichText}
                projectId={sanityConnectInfo.projectId}
                dataset={sanityConnectInfo.dataset}
                components={portableTextComponents}
              />
            ) : (
              <p>{formatDynamicAges(author.description)}</p>
            )}
          </div>
        </div>
        <Articles blogs={blogs} isInfinite={true} limitPerSection={3} />

        <div className="bottom-about">
          <AboutCard about={about} />
        </div>

        <div className="bottom-support-band">
          <SupportBand support={paymentGateways} />
        </div>
      </AuthorStyles>
    </>
  );
}

export const query = graphql`
  query ($slug: String) {
    author: sanityAuthor(slug: { current: { eq: $slug } }) {
      _createdAt
      _updatedAt
      name
      slug {
        current
      }
      caption

      image {
        alt
        caption
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }

      description
      biography {
        _rawRichText
      }
    }

    blogs: allSanityBlog(
      filter: { blogAuthor: { slug: { current: { eq: $slug } } } }
    ) {
      nodes {
        blogCategory {
          title
          slug {
            current
          }
        }

        title
        slug {
          current
        }
        tags {
          title
          slug {
            current
          }
        }

        description

        image {
          alt
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }

    about: sanitySection(title: { eq: "About Family I Love" }) {
      title
      textEditor {
        _key
        _type
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    paymentGateways: sanitySection(title: { eq: "Support The Band" }) {
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      title
    }
  }
`;
